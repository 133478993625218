<template>
    <div id="root" class="main-container">
        <template v-if="userInfo">
            <!-- 左侧菜单区 -->
            <MainMenu class="main-menu-box"/>
            <!-- 右侧视图 -->
            <div class="main-container-content">
                <!-- 上部导航区 -->
                <Navbar/>
                <!-- 子应用渲染区 -->
                <div class="main-container-view">
                    <el-scrollbar class="wl-scroll" style="height: 100%;">
                        <div id="app-viewport" class="app-view-box"></div>
                    </el-scrollbar>
                </div>
                <ul class="fiexd">
                    <li>
                        <el-popover
                                placement="left-start"
                                trigger="hover"
                        >
                            <div>官方账号：<span style="text-decoration: underline;color: #3a77e2;cursor: pointer" @click="click">{{helpInfo.qq}}</span></div>
                            <template #reference>
                                <img :src="QQ.path"/>
                            </template>
                        </el-popover>
                    </li>
                    <li>
                        <el-popover
                                placement="left-start"
                                trigger="hover"
                                :content="helpInfo.wechat"
                        >
                            <div>
                                <img :src="helpInfo.wechat" style="display:flex;width: 150px;width: 150px">
                                <span style="width: 150px; text-align: center; display: inline-block; ">扫码关注</span>
                            </div>
                            <template #reference>
                                <img :src="Wechat.path"/>
                            </template>
                        </el-popover>
                    </li>
                    <li>
                        <el-popover
                                placement="left-start"
                                :width="180"
                                trigger="hover"
                        >
                            <div>电话拨号：<span style="text-decoration: underline;color: #3a77e2;cursor: pointer" @click="click">{{helpInfo.phone}}</span></div>
                            <template #reference>
                                <img :src="Phone.path"/>
                            </template>
                        </el-popover>
                    </li>
                    <li style="border-bottom: none">
                        <el-popover
                                placement="left-start"
                                :width="300"
                                trigger="hover"
                        >
                            <div>公司地址：<span style="text-decoration: underline;color: #3a77e2;cursor: pointer" @click="click">{{helpInfo.address}}</span></div>
                            <template #reference>
                                <img :src="Address.path"/>
                            </template>
                        </el-popover>
                    </li>
                    <li style="border-bottom: none">
                        <el-popover
                                placement="left-start"
                                :width="300"
                                trigger="hover"
                        >
                            <div>技术服务：<span style="text-decoration: underline;color: #3a77e2;cursor: pointer">15190205003 孟工</span></div>
                            <template #reference>
                                <img :src="service.path"/>
                            </template>
                        </el-popover>
                    </li>
                </ul>
            </div>
        </template>
        <template v-else>
            <div id="app-viewport" class="app-view-box" style="width: 100%;height: 100%;"></div>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"; // 引入状态共享
    import MainMenu from "@/components/Main-Menu.vue";
    import Navbar from "@/components/Navbar.vue";
    import {apiPost} from "@/utils/axios-utils";
    import {ElMessageBox, ElMessage} from 'element-plus'

    export default {
        name: "root",
        components: {
            MainMenu,
            Navbar
        },
        mounted() {
            let that = this
            if (sessionStorage.getItem('userInfo')) {
                this.$store.dispatch('app/setUserInfo', JSON.parse(sessionStorage.getItem("userInfo")));
                this.getRole(JSON.parse(sessionStorage.getItem("userInfo")).roleId, JSON.parse(sessionStorage.getItem("userInfo")).appId)
            }
            window.addEventListener('beforeunload', () => {
                that.$store.getters.userInfo && sessionStorage.setItem("userInfo", JSON.stringify(that.$store.getters.userInfo))
                that.$store.getters.breadcrumb && sessionStorage.setItem("breadcrumb", JSON.stringify(that.$store.getters.breadcrumb))
                that.$store.getters.navbread && sessionStorage.setItem("navbread", JSON.stringify(that.$store.getters.navbread))
                that.$store.getters.activeIndex && sessionStorage.setItem("activeIndex", that.$store.getters.activeIndex)
                that.$store.getters.system && sessionStorage.setItem("system", that.$store.getters.system)
            })
            window.addEventListener('load', () => {
                that.$store.getters.userInfo && sessionStorage.setItem("userInfo", JSON.stringify(that.$store.getters.userInfo))
                that.$store.getters.breadcrumb && sessionStorage.setItem("breadcrumb", JSON.stringify(that.$store.getters.breadcrumb))
                that.$store.getters.navbread && sessionStorage.setItem("navbread", JSON.stringify(that.$store.getters.navbread))
                that.$store.getters.activeIndex && sessionStorage.setItem("activeIndex", that.$store.getters.activeIndex)
                that.$store.getters.system && sessionStorage.setItem("system", that.$store.getters.system)
            })
            window.onbeforeunload = () => {
                that.$store.getters.userInfo && sessionStorage.setItem("userInfo", JSON.stringify(that.$store.getters.userInfo))
                that.$store.getters.breadcrumb && sessionStorage.setItem("breadcrumb", JSON.stringify(that.$store.getters.breadcrumb))
                that.$store.getters.navbread && sessionStorage.setItem("navbread", JSON.stringify(that.$store.getters.navbread))
                that.$store.getters.activeIndex && sessionStorage.setItem("activeIndex", that.$store.getters.activeIndex)
                that.$store.getters.system && sessionStorage.setItem("system", that.$store.getters.system)
                // that.$store.getters.roleInfo && sessionStorage.setItem("roleInfo", that.$store.getters.roleInfo)
            }
            if(this.userInfo){
                this.getHelpInfo()
            }
        },
        beforeUnmount() {
            console.log('beforeDestroy')
        },
        data() {
            return {
                QQ:{
                    path:require('./static/img/QQ.png'),
                    source:""
                },
                Wechat:{
                    path:require('./static/img/WECHAT.png'),
                    source:""
                },
                Phone:{
                    path:require('./static/img/PHONE.png'),
                    source:""
                },
                Address:{
                    path:require('./static/img/ADDRESS.png'),
                    source:""
                },
                service:{
                    path:require('./static/img/service.png'),
                    source:""
                },
                helpInfo:{
                    wechat: ''
                }
            }
        },
        computed: {
            ...mapGetters(["userInfo"]),
        },
        methods: {
            getRole(roleId, appId) {
                const that = this;
                let formdata = new FormData()
                formdata.append('roleId', roleId)
                formdata.append('appId', appId)
                apiPost(this.$basic + this.$getRoleJurisdiction, formdata).then(res => {
                    if (res.data.code == '0000') {
                        if (res.data.attachObject) {
                            that.$store.getters.roleInfo && sessionStorage.setItem("roleInfo", that.$store.getters.roleInfo)
                        }
                    } else {
                        /* ElMessage({
                            message: '获取角色权限失败',
                            type: 'error',
                        }) */
                    }
                })
            },
            getHelpInfo() {
                const that = this;
                let formdata = new FormData()
                formdata.append('id', 1)
                apiPost(this.$common + this.$searchHelpInfo, formdata).then(res => {
                    if (res.data) {
                        that.helpInfo = res.data
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            click(val){
                var cInput = document.createElement("input");
                cInput.value = val.currentTarget.textContent;
                document.body.appendChild(cInput);
                cInput.select(); // 选取文本框内容

                document.execCommand("copy");

                ElMessage({
                            message: '复制成功',
                            type: 'success',
                        })
                // 复制成功后再将构造的标签 移除
                document.body.removeChild(cInput);
            }
        }
    };
</script>

<style lang="scss">
    html,
    body, #app-viewport, #app-viewport > div {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    #main-container {
        height: 100%;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .fiexd{
        position: fixed;
        right: 20px;
        bottom: calc(50% - 100px);
        list-style: none;
        width: 20px;
        border-radius: 24px;
        background: #fff;
        box-shadow: 0 2px 15px 0 #0000001a;
        display: inline-block;
        padding: 15px;
        z-index: 9999;
        li{
            height: 40px;
            border-bottom: 1px solid #ededec;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                height: 20px;
                width: 20px;
            }
        }
    }

    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    body::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    /*定义滑块 内阴影+圆角*/
    body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(64, 158, 255);
        background-color: #555;
    }

    .el-scrollbar__view {
        height: 100%;
    }

    .main-container {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .main-container-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        > .main-container-view {
            padding: 8px;
            width: 100%;
            height: calc(100% - 92px);
            background: $main-base-color;
            box-sizing: border-box;
            > .wl-scroll {
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 4px;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }

            .app-view-box {
                width: 100%;
                padding: 12px;
                box-sizing: border-box;
            }
        }
    }

    .subapp-loading {
        background: url("~@/assets/images/loading.gif");
    }
    .el-sub-menu__icon-arrow{
        display: none!important;
    }
</style>
<style>
    .el-popover{
     width:initial!important;       
     }
</style>
