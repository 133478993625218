<template>
    <div class="the-nav">
        <div class="the-breadcrumb">
            <!-- 菜单折叠按钮 -->
            <div class="nav-handle-collapse">
                <component :is="isCollapse?'Expand':'Fold'" :width="20" :height="20" style="margin: 14px 10px;"
                           @click="setMenuCollapseStatus()"/>
                <el-breadcrumb v-if="navbread" :separator-icon="ArrowRight" style="line-height: 50px">
                    <el-breadcrumb-item v-if="language == 'en' ? navbread.parentEnglish :navbread.parentChinese">{{language == "en"? navbread.parentEnglish : navbread.parentChinese}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: navbread.path }">{{language == "en"? navbread.childEnglish : navbread.childChinese}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="user">
                <!-- <div style="cursor: pointer; display: flex; align-items: center;" >
                    <img class="language" src="../static/img/login/language.png" alt="" style="width: 18px; height: 18px; margin-right: 6px;">
                    <span style="width: auto; font-size: 14px;" @click="changeLang">{{lang=="en" ? "English":"中文"}}</span>
                </div> -->
                <div>
                    {{currentDay}}
                    <span>欢迎登录{{user.enterprise?user.enterprise:user.userName}}</span>
                </div>
                <!-- <el-dropdown :hide-on-click="false" @command="switchMessage" style="margin-top: 16px;margin-left: 0px; width: 50px; display: flex; justify-content: center;">
                    <span class="el-dropdown-link" style="margin-top: 0px;">
                        <el-badge :value="noticeCount" class="item" :max="99">
                            <el-icon  color="#909399" :size="25">
                                <Notification style="font-size: 25px; width:25px;"/>
                            </el-icon>
                        </el-badge>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu style="display: flex; align-items: flex-start; width: 300px; height: 300px; position: relative;">
                            <div class="noticeItems">
                                <div v-for="item in noticeList" :key="item.id" class="messageItem" @click="detailNotice(item)" :style="{color: item.taskStatus == '即将超期' ? '#ef7d08': '' ||  item.taskStatus == '已超期' ? 'red': ''}">
                                    <p>{{item.taskName}}----{{item.taskStatus}}</p>
                                    <div>负责人：{{item.personInChargeName}}</div>
                                    <div>截止时间：<span>{{item.taskDeadline}}</span></div>
                                </div>
                            </div>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown> -->
                <el-dropdown :hide-on-click="false" @command="switchMessage" style="margin-top: 16px;margin-left: 0px; width: 50px; display: flex;">
                    <span class="el-dropdown-link" style="margin-top: 0px;">
                        <el-badge :value="messageValue" class="item" :max="99">
                            <el-icon  color="#909399" :size="30">
                                <Bell style="font-size: 30px; width:30px;"/>
                            </el-icon>
                        </el-badge>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu style="display: flex; align-items: flex-start; width: 300px; height: 300px; position: relative;">
                            <el-dropdown-item class="dropItem" command="notice">任务</el-dropdown-item>
                            <el-dropdown-item class="dropItem" command="alarm">检验</el-dropdown-item>
                            <el-dropdown-item class="dropItem" command="system">工单</el-dropdown-item>
                            <div class="messageItems">
                                <div v-if="currentCommand == 'notice'">
                                    <div v-for="item in noticeList" :key="item.id" class="messageItem" @click="detailNotice(item)"
                                    :style="{color: item.taskStatus == '即将超期' ? '#ef7d08': '' ||  item.taskStatus == '已超期' ? 'red': ''}">
                                        <p>{{item.taskName}}----{{item.taskStatus}}</p>
                                        <div>负责人：{{item.personInChargeName}}</div>
                                        <div>截止时间：<span>{{item.taskDeadline}}</span></div>
                                    </div>
                                </div>
                                <div v-if="currentCommand == 'alarm'">
                                    <div v-for="item in testList" :key="item.id" class="messageItem" @click="detailTest(item)">
                                        <p>{{item.enterpriseName}}</p>
                                        <div style="display: flex;">
                                            <div style="flex: 1;">检验类型：{{item.inspectionTypeName}}</div>
                                            <div style="flex: 1;">状态：{{item.status}}</div>
                                        </div>
                                        <div style="display: flex;">
                                            <div style="flex: 1;">申报人：{{item.originatorName}}</div>
                                            <div style="flex: 1;" v-if="item.status == '检验中'">处理人：{{item.dispatchersName}}</div>
                                        </div>
                                        <div>报检时间：<span>{{item.createTime}}</span></div>
                                    </div>
                                </div>
                                <div v-if="currentCommand == 'system'">
                                    <div v-for="item in workList" :key="item.id" class="messageItem" @click="detailWork(item)">
                                        <p>{{item.enterpriseName}}</p>
                                        <div style="flex: 1;">状态：{{item.status}}</div>
                                        <div style="flex: 1;">处理人：{{item.repairerName}}</div>
                                        <div>建单时间：<span>{{ renderTime(item.createTime)}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-dropdown @command="changeRouter">
                    <div class="el-dropdown-link">
                        <!--<el-avatar shape="square" :size="50" src="../static/img/home/head.png"></el-avatar>-->
                        <img class="headP" :src="headPortrait" alt="">
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="pass">{{$t('updatepass')}}</el-dropdown-item>
                            <el-dropdown-item command="info">{{$t('personalInfo')}}</el-dropdown-item>
                            <el-dropdown-item command="exit">{{$t('logout')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <!--<div v-else class="user" style="justify-content:end">-->
                <!--<el-dropdown @command="changeRouter">-->
                    <!--<div class="el-dropdown-link">-->
                        <!--&lt;!&ndash;<el-avatar shape="square" :size="50" src="../static/img/home/head.png"></el-avatar>&ndash;&gt;-->
                        <!--<img class="headP" :src="headPortrait" alt="">-->
                    <!--</div>-->
                    <!--<template #dropdown>-->
                        <!--<el-dropdown-menu>-->
                            <!--<el-dropdown-item command="pass">{{$t('updatepass')}}</el-dropdown-item>-->
                            <!--<el-dropdown-item command="info">{{$t('personalInfo')}}</el-dropdown-item>-->
                            <!--<el-dropdown-item command="exit">{{$t('logout')}}</el-dropdown-item>-->
                        <!--</el-dropdown-menu>-->
                    <!--</template>-->
                <!--</el-dropdown>-->
            <!--</div>-->
        </div>
        <div class="the-tagcrumb">
            <router-link :class="navbread && navbread.mid == v.mid?'bread active-bread':'bread'" class="bread"
                         v-for="(v, i) in breadcrumb" :key="i" :to="v.path" @click="addNavbread(v)">
                {{language == "en"? v.childEnglish : v.childChinese}}
                <el-icon v-if="i" @click.stop="delBreadCrumb(v)" style="vertical-align: middle;"
                         :color="navbread && navbread.mid == v.mid ?'#FFFFFF':'#409eff'">
                    <CloseBold/>
                </el-icon>
            </router-link>
        </div>
        <el-dialog v-model="visiblePassword" :title="$t('updatepass')">
            <el-form :model="setPassword" :rules="rules" label-width="120px">
                <el-form-item :label="$t('oldRepass')">
                    <el-input type="password" v-model="setPassword.orgPassword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('newRepass')">
                    <el-input type="password" v-model="setPassword.newPassword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('confirmRepass')">
                    <el-input type="password" v-model="setPassword.confirmPassword"></el-input>
                </el-form-item>
                <div style="display: flex;justify-content: space-around">
                    <el-button @click="cancel">{{$t('cancel')}}</el-button>
                    <el-button type="primary" @click="submit">{{$t('confirm')}}</el-button>
                </div>
            </el-form>
        </el-dialog>
        <el-dialog v-model="visibleUser" :title="$t('personalInfo')">
            <el-form :model="user" label-width="120px">
                <el-form-item :label="$t('head_portrait')">
                    <el-upload
                            class="avatar-uploader"
                            action="#"
                            :show-file-list="false"
                            :http-request="httpRequest"
                            :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="user.headPortrait" :src="user.headPortrait" class="avatar"
                             style="height: 100px;width: 100px;"/>
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus/>
                        </el-icon>
                    </el-upload>
                </el-form-item>
                <el-form-item :label="$t('name')">
                    <el-input :disabled="editor" v-model="user.userName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('department')">
                    <el-input disabled v-model="user.departmentName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('role')">
                    <el-input disabled v-model="user.roleName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('phone')">
                    <el-input :disabled="editor" v-model="user.phone"></el-input>
                </el-form-item>
                <el-form-item :label="$t('email')">
                    <el-input :disabled="editor" v-model="user.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('sms_reception')">
                    <!-- <el-input :disabled="editor" v-model="user.isMessage"></el-input> -->
                    <el-checkbox :disabled="editor" v-model="user.isPhone">{{$t('receive_or_not')}}</el-checkbox>
                </el-form-item>
                <el-form-item :label="$t('email_reception')">
                    <!-- <el-input :disabled="editor" v-model="user.isEmail"></el-input> -->
                    <el-checkbox :disabled="editor" v-model="user.isMail">{{$t('receive_or_not')}}</el-checkbox>
                </el-form-item>
                <div style="display: flex;justify-content: space-around">
                    <el-button @click="cancelUser">{{$t('cancel')}}</el-button>
                    <el-button type="primary" @click="submitUser">{{$t('confirm')}}</el-button>
                </div>
            </el-form>
        </el-dialog>
        <el-dialog v-model="visibleMessage" :title="messageTitle" :draggable="true" @close="confirmMessage" class="visibleMessageDialog">
            <div>
                <p><span style="color: #000;">消息内容：</span> <br> {{messageDetail.content}}</p>
                <p style="margin-top: 16px;"><span style="color: #000;">{{$t('message_time')}}</span>{{messageDetail.time}}</p>
            </div>
            <!-- <div style="display: flex;justify-content: space-around; margin-top: 20px;">
                <el-button @click="cancelMessage">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="confirmMessage">{{$t('confirm')}}</el-button>
            </div> -->
        </el-dialog>
        <el-dialog v-model="visibleNotice" title="任务通知" :draggable="true" class="visibleMessageDialog">
            <div>
                <p><span style="color: #000;">通知内容：</span> <br> {{noticeDetail.content}}</p>
                <p v-if="noticeDetail.bellDelayTime">下次完成时间：<span>{{noticeDetail.bellDelayTime}}</span></p>
                <p style="margin-top: 16px;"><span style="color: #000;">{{$t('message_time')}}</span>{{noticeDetail.createTime}}</p>
            </div>
            <div style="display: flex;justify-content: space-around; margin-top: 20px;" v-if="[ '延期申请', '完成申请' ].includes(noticeDetail.bellStatus) && !noticeDetail.isRead && editStatus">
                <el-button @click="cancelNotice">拒绝</el-button>
                <el-button type="primary" @click="confirmNotice">通过</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {Expand, Fold, Bell, SetUp, CloseBold, View, Plus, Sort} from "@element-plus/icons";
    import {ElMessageBox, ElMessage} from 'element-plus'
    import {mapGetters} from "vuex";
    import {apiPost} from "@/utils/axios-utils";
    import {routerGo} from "@/utils/utils.js"; // 引入跨应用路由跳转
    import {noAuthApps, nextAuthApps} from "@/core/core-config";
    import qianKunStart from "@/core/app-register"
    import {ElLoading} from 'element-plus'
    import {setState} from "../utils/app-store";
    import axios from 'axios'
    import qs from 'qs'

    export default {
        name: "theNav",
        data() {
            return {
                deleteStatus:0,
                currentDay: '',
                visibleMessage: false,
                messageTitle: '',
                messageDetail: {},
                user_name: "Admin", // 名称 // 用户信息
                full_screen: false, // 是否全屏
                setPassword: {
                    orgPassword: null,
                    newPassword: null,
                    confirmPassword: null
                },
                visiblePassword: false,
                rules: {},
                user: {
                    isPhone: false,
                    isMail: false
                },
                visibleUser: false,
                editor: false,
                currentnavbread: null,
                currentSystem: "monitor",  // maintenance
                userMessageList: [],
                messageCount: 0,
                drawer: false,
                color: '#F5F5F5',
                headPortrait: require('@/static/img/home/headPortrait.png'),
                allMessage: [],
                message: [],
                noticeMessage: [],
                alarmMessage: [],
                systemMessage: [],
                messageValue: 0,
                socket: null,
                topic: '',
                currentCommand: 'notice',
                lang:'',
                language: sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : 'ch',

                // 超弦通知
                noticeCount: 0,
                noticeList: [],
                visibleNotice: false,
                noticeDetail: {},
                editStatus: 1,
                chaoxianSocket: null,
                chaoxianTopic: {},
                testList: [],
                workList: [],
            };
        },
        components: {
            Expand, Fold, Bell, SetUp, CloseBold, View, Plus, Sort
        },
        computed: {
            isCollapse() {
                return this.$store.getters.is_collapse;
            },
            ...mapGetters(["breadcrumb"]),
            ...mapGetters(["navbread"]),
            ...mapGetters({subappMsg: "msg"})
        },
        beforeMount() {
            this.user = this.$store.getters.userInfo
            setState({
                msg: {menu: JSON.parse(sessionStorage.getItem('menu')),userInfo: this.user},
            })
            if (this.user.headPortrait) this.headPortrait = this.user.headPortrait
            this.getUserMessage()
            // this.getAllMessage()
        },
        mounted() {
            setTimeout(() => {
                this.getJurisdiction()
            }, 2000)
            this.lang = sessionStorage.getItem("lang")
            let that = this
            if (that.$store.getters.system && that.$store.getters.system == "maintenance") that.currentSystem = "maintenance"
            if (sessionStorage.getItem('breadcrumb')) {
                let breadcrumbs = JSON.parse(sessionStorage.getItem("breadcrumb"))
                breadcrumbs.forEach(breadcrumb => {
                    let sub = {
                        guideChinese: breadcrumb.parentChinese,
                        guideEnglish: breadcrumb.parentEnglish,
                        entry: breadcrumb.entry,
                    }
                    let item = {
                        path: breadcrumb.path,
                        mid: breadcrumb.mid,
                        guideChinese: breadcrumb.childChinese,
                        guideEnglish: breadcrumb.childEnglish,
                    }
                    this.$store.dispatch('menu/addBreadCrumb', {sub, item});
                })
            }
            if (sessionStorage.getItem('activeIndex')) {
                that.$store.dispatch('menu/setActiveIndex', sessionStorage.getItem('activeIndex'));
            }
            if (sessionStorage.getItem('navbread')) {
                let navbread = JSON.parse(sessionStorage.getItem("navbread"))
                this.currentnavbread = navbread
                let sub = {
                    guideChinese: navbread.parentChinese,
                    guideEnglish: navbread.parentEnglish,
                    entry: navbread.entry,
                }
                let item = {
                    path: navbread.path,
                    mid: navbread.mid,
                    guideChinese: navbread.childChinese,
                    guideEnglish: navbread.childEnglish,
                }
                this.$store.dispatch('menu/addNavBread', {sub, item})
                // this.goto(null, navbread.path)


                if(window.location.href.indexOf(navbread.path) < 0 && navbread.path){
                    window.location.href = window.location.origin + navbread.path
                }
                this.getNotice()
            }

            this.getCurrentDate()
            window.onbeforeunload = function() {
                var e = window.event
                // this.chaoxianSocketClose()
            }
        },
        methods: {
            renderTime (date) {
                let dateee = new Date(date).toJSON();
                return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
            },
            getCurrentDate() {
                let obj = {'0': '星期日','1': '星期一','2': '星期二','3': '星期三','4': '星期四','5': '星期五','6': '星期六'}
                let date = new Date()
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                let day = date.getDate()
                let week = date.getDay() + ''
                if (month < 10) {
                    month = '0' + month
                }
                this.currentDay = `${year}年${month}月${day}日 ${obj[week]}`
            },
            //超弦任务通知
            getNotice() {
                /* let fd = new FormData()
                fd.append('userId', this.user.id)
                fd.append('isExternal', this.user.isExternal || '')
                fd.append('appId', this.user.appId)
                apiPost(this.$contractcenter + this.$queryAllForDell, fd).then(res => {
                    if (res.data.code == '0000') {
                        console.log(res)
                        this.noticeCount = res.data.total
                        this.noticeList = res.data.attachObject || []
                    } else {
                        this.$message.error('通知获取失败')
                    }
                }) */
                let fd = new FormData()
                fd.append('pageNum', 1)
                fd.append('pageSize', 999999)
                fd.append('userId', this.user.id)
                fd.append('appId', this.user.appId)
                fd.append('isExternal', this.user.isExternal || '')
                fd.append('isTaskAdmin', this.user.isTaskAdmin || '')
                fd.append('isTestAdmin', this.user.isTestAdmin || '')
                fd.append('isWorkOrderAdmin', this.user.isWorkOrderAdmin || '')
                fd.append('departmentId', this.user.departmentId || '')
                fd.append('isShowComplete', '已完成')
                apiPost(this.$contractcenter + this.$queryAllForDell, fd).then(res => {
                    if (res.data.code == '0000') {
                        this.noticeList = res.data.attachObject.task || []
                        this.testList = res.data.attachObject.inspectionMenus || []
                        this.workList = res.data.attachObject.workorders || []
                        this.messageValue = this.noticeList.length + this.testList.length + this.workList.length
                        let j = 0
                        let temp = null
                        for (let i = 0; i < this.noticeList.length; i++) {
                            if (this.noticeList[i].taskStatus == '即将超期') {
                                temp = this.noticeList[i]
                                this.noticeList[i] = this.noticeList[j]
                                this.noticeList[j] = temp
                                j++

                            }
                        }
                    } else {
                        ElMessage.error('通知列表获取失败')
                    }
                })
            },
            detailNotice(data) {
                window.history.pushState(null, "", "/cloud-basic/task/taskManager?taskName=" + data.taskName );
            },
            detailTest(data) {
                window.history.pushState(null, "", "/cloud-basic/inspection/inspection-sheet?name=" + data.name );
            },
            detailWork(data) {
                window.history.pushState(null, "", "/cloud-basic/work-order-center/work-center?workCode=" + data.workCode );
            },
            cancelNotice() {
                let fd = new FormData()
                fd.append('bellId', this.noticeDetail.id)
                fd.append('taskId', this.noticeDetail.taskId)
                fd.append('taskName', this.noticeDetail.taskName)
                fd.append('userId', this.user.id)
                if (this.noticeDetail.bellStatus == '完成申请') {
                    fd.append('flag', '完成申请失败')
                } else {
                    fd.append('flag', '延期申请失败')
                }
                if (this.noticeDetail.bellDelayTime) {
                    fd.append('bellDelayTime', this.noticeDetail.bellDelayTime) 
                }
                apiPost(this.$contractcenter + this.$tasktaskrecord, fd).then(res => {
                    if (res.data.code == '0000') {
                        this.visibleNotice = false
                    } else {
                        ElMessage.error('保存失败')
                    }
                })
            },
            confirmNotice() {
                let fd = new FormData()
                fd.append('bellId', this.noticeDetail.id)
                fd.append('taskId', this.noticeDetail.taskId)
                fd.append('taskName', this.noticeDetail.taskName)
                fd.append('userId', this.user.id)
                if (this.noticeDetail.bellStatus == '完成申请') {
                    fd.append('flag', '完成申请成功')
                } else {
                    fd.append('flag', '延期申请完成')
                }
                if (this.noticeDetail.bellDelayTime) {
                    fd.append('bellDelayTime', this.noticeDetail.bellDelayTime) 
                }
                apiPost(this.$contractcenter + this.$tasktaskrecord, fd).then(res => {
                    if (res.data.code == '0000') {
                        this.visibleNotice = false
                    } else {
                        ElMessage.error('保存失败')
                    }
                })
                /* let data = {
                    bellId: this.noticeDetail.id,
                    taskId: this.noticeDetail.taskId,
                    taskName: this.noticeDetail.taskName,
                    userId: this.user.id
                }
                if (this.noticeDetail.bellStatus == '完成申请') {
                    data.flag = '完成申请成功'
                } else {
                    data.flag = '延期申请完成'
                }
                axios({
                    method: 'POST',
                    url: 'http://192.168.0.59:8801/task/taskrecord',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data: qs.stringify(data)
                }).then(res => {
                    if (res.data.code == '0000') {
                        this.visibleNotice = false
                    } else {
                        ElMessage.error('保存失败')
                    }
                }) */
            },
            // 获取权限
            getJurisdiction() {
                let menus = JSON.parse(sessionStorage.getItem("menu"))
                for (let i in menus) {
                    if (menus[i].children) {
                        for (let j in menus[i].children) {
                            if (menus[i].children[j].path == this.$route.path.substring(this.$route.path.indexOf('/') + 12)) {
                                this.readStatus = menus[i].children[j].readStatus
                                this.editStatus = menus[i].children[j].editStatus
                                this.deleteStatus = menus[i].children[j].deleteStatus
                                this.menuId = menus[i].children[j].id
                                break
                            }
                        }
                    }
                }
                sessionStorage.setItem('menuId', this.menuId)
                this.jurisdiction = {
                    readStatus: this.readStatus,
                    editStatus: this.readStatus,
                    deleteStatus: this.deleteStatus,
                    menuId: this.menuId
                }
            },
            changeLang() {
                let str = window.event.target.innerHTML
                if (str == '中文') {
                    this.$i18n.locale = 'en';
                    this.lang = "English";
                    sessionStorage.setItem('lang', 'en')
                } else {
                    this.$i18n.locale = 'ch';
                    this.lang = "中文";
                    sessionStorage.setItem('lang', 'ch')
                }
                window.location.reload()
            },
            jump() {
                let url = ""
                switch (this.user.industry) {
                    case 1:
                        url = this.$environment_protection + "/cloud-login/?account=epuser&password=123456"
                        break;
                    case 2:
                        url = this.$energy + "/cloud-login/?account=esuser&password=123456"
                        break;
                    case 3:
                        url = this.$factory + "/cloud-login/?account=peuser&password=123456"
                        break;
                    case 4:
                        url = this.$other + "/cloud-login/?account=spcluser&password=123456"
                        break;
                }
                window.open(url);
            },
            switchSystem() {
                let that = this
                const loading = ElLoading.service({
                    lock: true,
                    text: '系统切换中...',
                    background: 'rgba(255, 255, 255, 0.5)',

                })
                that.currentSystem = that.currentSystem == "monitor" ? "maintenance" : "monitor"
                that.$store.dispatch('app/setSystem', that.currentSystem);
                let menu = sessionStorage.getItem("menu")
                let firstMenu = null
                setTimeout(() => {
                    that.$store.dispatch('menu/clearBreadCrumb');
                    if (menu) {
                        menu = JSON.parse(sessionStorage.getItem("menu"))
                        setState({
                            msg: {menu: menu,userInfo: this.user},
                        })
                        if (that.currentSystem == "maintenance") {

                            for (let i in menu) {
                                if (menu[i].pluginId == 5) {
                                    firstMenu = menu[i]
                                    let sub = {
                                        guideChinese: firstMenu.guideChinese,
                                        guideEnglish: firstMenu.guideEnglish,
                                        entry: firstMenu.activeRule,
                                    }
                                    let item = {
                                        path: firstMenu.activeRule + firstMenu.children[0].path,
                                        mid: firstMenu.children[0].mid,
                                        guideChinese: firstMenu.children[0].guideChinese,
                                        guideEnglish: firstMenu.children[0].guideEnglish,
                                    }
                                    that.$store.dispatch('menu/addNavBread', {sub, item})
                                    that.$store.dispatch('menu/addBreadCrumb', {sub, item});
                                    that.goto(null, firstMenu.activeRule + firstMenu.children[0].path)
                                    break
                                }
                            }
                        } else {
                            for (let i in menu) {
                                if (menu[i].pluginId == 2) {
                                    firstMenu = menu[i]
                                    let sub = {
                                        guideChinese: firstMenu.guideChinese,
                                        guideEnglish: firstMenu.guideEnglish,
                                        entry: firstMenu.activeRule,
                                    }
                                    let item = {
                                        path: firstMenu.activeRule + firstMenu.children[0].path,
                                        mid: firstMenu.children[0].mid,
                                        guideChinese: firstMenu.children[0].guideChinese,
                                        guideEnglish: firstMenu.children[0].guideEnglish,
                                    }
                                    that.$store.dispatch('menu/addNavBread', {sub, item})
                                    that.$store.dispatch('menu/addBreadCrumb', {sub, item});
                                    that.goto(null, firstMenu.activeRule + firstMenu.children[0].path)
                                    break
                                }
                            }
                        }
                    }
                    loading.close()
                }, 1000)
            },
            goto(module, href) {
                const loading = ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.5)',

                })
                setTimeout(() => {
                    loading.close()
                }, 1000)
                routerGo(href, module);
            }
            ,
            // 设置左侧菜单折叠状态
            setMenuCollapseStatus() {
                this.$store.dispatch("menu/setCollapseStatus", !this.isCollapse);
            }
            ,
            changeRouter(i) {
                let that = this
                if (i === 'pass') {
                    that.setPassword = {
                        orgPassword: null,
                        newPassword: null,
                        confirmPassword: null
                    }
                    that.visiblePassword = true
                } else if (i === 'info') {
                    that.visibleUser = true
                } else if (i === 'exit') {
                    let formdata = new FormData()
                    formdata.append('account', that.user.account)
                    formdata.append('token', that.user.token)
                    formdata.append('client', "pc")
                    apiPost(that.$basic + that.$loginOut, formdata).then((res) => {
                        if (res.data.code == "0000") {
                            ElMessage({
                                message: this.$t('logoutSuccess'),
                                type: 'success',
                            })
                            qianKunStart(noAuthApps)
                            setTimeout(() => {
                                this.goto(null, "/")
                                window?.location?.reload?.();
                                sessionStorage.clear();
                                that.$store.dispatch('app/setUserInfo', null);
                                that.$store.dispatch('app/setToken', null);
                                that.$store.dispatch('app/setSystem', null);
                                that.$store.dispatch('menu/clearBreadCrumb', null);
                            }, 1000)

                        }
                    })
                }
            }
            ,
            delBreadCrumb(dreadCrumb) {
                this.$store.dispatch("menu/delBreadCrumb", dreadCrumb);
                let bread = this.$store.state.menu.breadcrumb[this.$store.state.menu.breadcrumb.length - 1]
                this.$store.dispatch('menu/addNavBread', {bread})
                this.currentnavbread = bread
                // var path = bread.path
                // if (path !== this.$route.path) this.$router.push(path)var path = bread.path
                // if (path !== this.$route.path) this.$router.push(path)

                this.$store.getters.breadcrumb && sessionStorage.setItem("breadcrumb", JSON.stringify(this.$store.getters.breadcrumb))
                this.$store.getters.navbread && sessionStorage.setItem("navbread", JSON.stringify(this.$store.getters.navbread))
                this.goto(null, bread.path)
                // that.$store.getters.userInfo && sessionStorage.setItem("userInfo", JSON.stringify(that.$store.getters.userInfo))
                // that.$store.getters.breadcrumb && sessionStorage.setItem("breadcrumb", JSON.stringify(that.$store.getters.breadcrumb))
                // that.$store.getters.navbread && sessionStorage.setItem("navbread", JSON.stringify(that.$store.getters.navbread))
                // that.$store.getters.activeIndex && sessionStorage.setItem("activeIndex", that.$store.getters.activeIndex)
                // that.$store.getters.system && sessionStorage.setItem("system", that.$store.getters.system)
            }
            ,
            addNavbread(bread) {
                this.$store.dispatch('menu/addNavBread', {bread})
                this.currentnavbread = bread
                this.goto(null, bread.path)
            }
            ,
            submit() {
                const that = this
                ElMessageBox.confirm(this.$t('confirm_password_change'), this.$t('Tips'), {
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                }).then(() => {
                    let formdata = new FormData();
                    formdata.append("account", that.user.account)
                    formdata.append("orgPassword", that.setPassword.orgPassword)
                    formdata.append("newPassword", that.setPassword.newPassword)
                    formdata.append("confirmPassword", that.setPassword.confirmPassword)
                    apiPost(that.$basic + that.$repass, formdata).then((res) => {
                        if (res.data.code == "0000") {
                            sessionStorage.clear();
                            that.$store.dispatch('app/setUserInfo', null);
                            that.$store.dispatch('app/setToken', null);
                            ElMessage({
                                message: this.$t('contr_success'),
                                type: 'success',
                            })
                            qianKunStart(noAuthApps)
                            setTimeout(() => {
                                this.goto(null, "/index")
                                window?.location?.reload?.();
                            }, 1000)
                        } else {
                            ElMessage({
                                message: this.$t('contr_fail'),
                                type: 'warning',
                            })
                        }
                    })
                }).catch(() => {
                    ElMessage({
                        type: 'info',
                        message: this.$t('you_cancel_contr'),
                    })
                })
            }
            ,
            cancel() {
                this.visiblePassword = false
            }
            ,
            httpRequest(data) {
                const that = this
                let rd = new FileReader() // 创建文件读取对象
                let file = data.file
                rd.readAsDataURL(file) // 文件读取装换为base64类型
                rd.onload = function (e) {
                    that.user.headPortrait = e.target.result // this指向当前方法onloadend的作用域
                }
                this.user.headPortraitFile = file
            }
            ,
            beforeAvatarUpload(file) {
                const isLt5M = file.size / 1024 / 1024 <= 5;
                if (!isLt5M) {
                    ElMessage({
                        message: this.$t('upload_file'),
                        type: 'error',
                    })
                }
                return isLt5M;
            }
            ,
            submitUser() {
                const that = this
                let formdata = new FormData();
                formdata.append("id", this.user.id)
                formdata.append("account", this.user.account)
                formdata.append("headPortraitFile", this.user.headPortraitFile)
                formdata.append("userName", this.user.userName)
                formdata.append("phone", this.user.phone)
                formdata.append("email", this.user.email)
                formdata.append("isMail", this.user.isMail ? 1 : 0)
                formdata.append("isPhone", this.user.isPhone ? 1 : 0)
                apiPost(this.$basic + this.$editUser, formdata).then((res) => {
                    if (res.data.code == "0000") {
                        sessionStorage.setItem("userInfo", JSON.stringify(that.user))
                        let obj = this.$store.getters.userInfo
                        obj.id = this.user.id
                        obj.account = this.user.account
                        obj.headPortraitFile = this.user.headPortraitFile
                        obj.userName = this.user.userName
                        obj.phone = this.user.phone
                        obj.email = this.user.email
                        this.$store.dispatch('app/setUserInfo', obj)
                        that.headPortrait = that.user.headPortrait
                        this.visibleUser = false;
                        ElMessage({
                            message: this.$t('contr_success'),
                            type: 'success',
                        })
                    } else {
                        ElMessage({
                            message: this.$t('contr_fail'),
                            type: 'warning',
                        })
                    }
                })
            }
            ,
            cancelUser() {
                this.visibleUser = false;
            }
            ,
            getUserMessage() {
                let that = this
                let formData = new FormData
                formData.append('userId', that.user.id)
                formData.append('status', 0)

                apiPost(that.$maintenance + that.$userMessage.getUserMessage, formData).then((data) => {
                    if (data.data.code === '0000') {
                        that.userMessageList = data.data.attachObject
                        if (null === that.userMessageList || undefined === that.userMessageList)
                            that.messageCount = 0
                        else
                            that.messageCount = that.userMessageList.length
                    }
                })
            },
            readUserMessage(index) {
                let that = this
                let formData = new FormData
                formData.append('id', that.userMessageList[index].id)
                formData.append('status', 1)

                apiPost(that.$maintenance + that.$userMessage.readUserMessage, formData).then((data) => {
                    if (data.data.code === '0000') {
                        that.userMessageList.splice(index, 1);
                        that.messageCount = that.userMessageList.length
                    }
                })
            },
            enterDom(obj) {
                let dom = obj.currentTarget
                dom.style.backgroundColor = '#b1cef0'
            },
            leaveDom(obj) {
                let dom = obj.currentTarget
                dom.style.backgroundColor = '#F5F5F5'
            },
            getAllMessage() {
                let fd = new FormData()
                fd.append("appId", this.user.appId)
                fd.append("userId", this.user.id)
                apiPost(this.$basic + this.$getNotificationMessage, fd).then(res => {
                    if (res.data.code == 1) {
                        this.messageValue = res.data.count
                        this.allMessage = res.data.datas
                        this.noticeMessage = this.allMessage.filter(item => {
                            if (item.type == '通知' || item.type == 'Notice') {
                                return item
                            }
                        })
                        this.alarmMessage = this.allMessage.filter(item => {
                            if (item.type == '告警' || item.type == 'Alarm') {
                                return item
                            }
                        })
                        this.systemMessage = this.allMessage.filter(item => {
                            if (item.type == '系统' || item.type == 'System') {
                                item.content = item.operationer + '在' + item.visitAddress + item.operationAction
                                return item
                            }
                        })
                        this.message = this.noticeMessage
                        if (!this.socket) {
                            this.initScoket()
                            // this.chaoxianInitScoket()
                        }
                    } else {
                        console.log('实时消息获取失败')
                    }
                })
            },
            switchMessage(i) {
                let boxs = document.getElementsByClassName("dropItem")
                for (let i = 0; i < boxs.length; i++) {
                    boxs[i].style.background = 'rgba(0,0,0,0)'
                    boxs[i].style.color = '#999'
                }
                event.target.style.background = "#1684fc"
                event.target.style.color = "#fff"
                this.currentCommand = i
                /* switch(i) {
                    case 'notice':
                        this.message = this.noticeMessage;
                        break;
                    case 'alarm':
                        this.message = this.alarmMessage;
                        break;
                    case 'system':
                        this.message = this.systemMessage;
                        break;
                } */
            },
            goMoreMessage() {
                switch(this.currentCommand) {
                    case 'notice':
                        this.$router.push({path: '/cloud-basic/news/notice'})
                        break;
                    case 'alarm':
                        this.$router.push({path: '/cloud-basic/news/alarm'})
                        break;
                    case 'system':
                        this.$router.push({path: '/cloud-basic/news/system'})
                        break;
                }
            },
            detailMessage(data) {
                // console.log(data)
                this.messageTitle = data.type
                this.messageDetail = data
                this.visibleMessage = true
            },
            confirmMessage() {
                this.visibleMessage = false
                let arr = []
                arr.push(this.messageDetail.id)
                if (this.messageDetail.type == '通知') {
                    this.readNotificationMessage(arr)
                } else {
                    this.readAlarmMessage(arr)
                }
            },
            // cancelMessage() {},
            readNotificationMessage(arr) {
                let fd = new FormData()
                fd.append("appId", this.user.appId)
                fd.append("userId", this.user.id)
                fd.append("messageId", JSON.stringify(arr))
                apiPost(this.$basic + this.$readNotificationMessage, fd).then(res => {
                    if (res.data.code == 1) {
                        // this.$message.success('请求成功')
                        this.getAllMessage()
                    }
                })
            },
            readAlarmMessage(arr) {
                let fd = new FormData()
                fd.append("appId", this.user.appId)
                fd.append("userId", this.user.id)
                fd.append("messageId", JSON.stringify(arr))
                apiPost(this.$basic + this.$readAlarmMessage, fd).then(res => {
                    if (res.data.code == 1) {
                        // this.$message.success('请求成功')
                        this.getAllMessage()
                    }
                })
            },
            initScoket() {
                this.topic = this.user.appId + this.user.id + new Date().getDate()
                let url = this.$WEBSOCKET_SERVER_URL + '/socketMsg/' + this.topic
                this.socket = new WebSocket(url)
                this.socket.onopen = this.socketOpen
                this.socket.onmessage = this.socketMessage
                this.socket.onerror = this.socketError
                this.socket.onclose = this.socketClose
            },
            socketOpen() {
                let obj = {
                    appId: this.user.appId,
                    userId: this.user.id,
                }
                console.log('实时消息开始连接')
                this.socket.send("{linkStatus:1, topic:'" + this.topic + "',socketDT:1, datas:'" + JSON.stringify(obj) + "'}")
            },
            socketMessage(e) {
                let data = JSON.parse(e.data)
                switch(data.flag) {
                    case '通知':
                        this.noticeMessage.unshift(data)
                        break;
                    case '告警':
                        this.alarmMessage.unshift(data)
                        break;
                }
                this.messageValue++
            },
            socketError(e) {
                // console.log(e)
            },
            socketClose() {
                console.log("实时消息关闭")
                this.socket.close()
            },
            chaoxianInitScoket() {
                this.chaoxianTopic = this.user.appId + this.user.id + new Date().getDate()
                let url = this.$WEBSOCKET_SERVER_URL2 + '/socketMsg/' + this.chaoxianTopic
                this.chaoxianSocket = new WebSocket(url)
                this.chaoxianSocket.onopen = this.chaoxianSocketOpen
                this.chaoxianSocket.onmessage = this.chaoxianSocketMessage
                this.chaoxianSocket.onclose = this.chaoxianSocketClose
            },
            chaoxianSocketOpen() {
                let obj = {
                    appId: this.user.appId,
                    userId: this.user.id,
                }
                console.log('实时通知开始连接')
                this.chaoxianSocket.send("{linkStatus:1, topic:'" + this.chaoxianTopic + "',socketDT:1, datas:'" + JSON.stringify(obj) + "'}")
            },
            chaoxianSocketMessage(e) {
                let data = JSON.parse(e.data)
                console.log(data)
                this.noticeCount++
                this.noticeList.unshift(data)
            },
            chaoxianSocketClose() {
                console.log("实时通知关闭")
                this.chaoxianSocket.close()
            },
        }
    };
</script>

<style lang="scss" scoped>
    .the-nav {
        height: 95px;
        color: $header-color;
        box-shadow: #c1c0c0 1px 4px 5px 2px;
        box-sizing: border-box;
        .the-breadcrumb {
            // background-color: #4671c4;
            padding: 5px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #CCCCCC;
            .nav-handle-collapse {
                display: flex;
                justify-content: space-between;
            }
            .user {
                line-height: 50px;
                // width: 215px;
                display: flex;
                justify-content: space-between;
                vertical-align: middle;
                .el-dropdown-link {
                    cursor: pointer;
                    line-height: 10px;
                    span {
                        width: 30px;
                        display: inline-block;
                        box-sizing: border-box;
                        font-size: 14px;
                    }
                    img {
                        margin-right: 10px;
                    }
                }
                .el-icon-arrow-down {
                    font-size: 12px;
                    height: inherit;
                }
                .headP {
                    width: 50px;
                    height: 50px;
                    border-radius: 4px;
                    /*box-shadow: 0px 0px 5px 2px #93aabf;*/
                }
            }
        }
        .the-tagcrumb {
            display: flex;
            height: 35px;
            padding: 3px;
            .bread {
                border: 1px solid #409eff;
                /*border: 1px solid #00998a;*/
                margin: 0px 2px;
                height: 26px;
                line-height: 26px;
                border-radius: 4px;
                padding: 0px 7px;
                color: #409eff;
                /*color: #00998a;*/
                font-size: 12px;
                text-decoration: none;

                /*overflow: hidden;*/
                white-space: nowrap;
                /*text-overflow: ellipsis;*/
            }
            .active-bread {
                background: #409eff;
                /*background: #00998a;*/
                color: white;
            }
        }

    }
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
    .el-drawer.rtl {
        overflow: scroll
    }

    .el-dropdown-menu {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        z-index: var(--el-dropdown-menu-index) !important;
        padding: 10px 0 !important;
        margin: 0 !important;
        background-color: #fff !important;
        border: none !important;
        border-radius: var(--el-border-radius-base) !important;
        box-shadow: none !important;
    }
    .dropItem {
        border: 1px #1684fc solid;
        width: 33%;
        display: flex;
        justify-content: center;
        color: #2787fc;
    }
    .dropItem:nth-child(1) {
        background: #1684fc;
        color: #fff;
    }
    .messageItems {
        // background: red;
        position: absolute;
        top: 50px;
        // overflow: auto;
        width: calc(100% - 10px);
        padding: 5px;
    }
    .noticeItems {
        position: absolute;
        // top: 50px;
        // overflow: auto;
        width: calc(100% - 10px);
        padding: 5px;
    }
    .messageItem {
        background: #f5f5f5;
        // color: #fff;
        width: calc(100% - 10px);
        margin-top: 5px;
        // height: 50px;
        overflow: auto;
        min-height: 50px;
        padding: 5px;
    }
    .messageItem div {
        line-height: 20px;
        // margin-top: 5px;
    }
    .messageItem div:first-child {
        min-height: 30px;
        // background: red;
    }
    .messageItem div:last-child {
        text-align: right;
        // margin-top: 10px;
        > span {
            color: #2b53b8;
            font-weight: 550;
        }
    }
    .el-dialog {
        margin-top: 100px;
        top: 17% !important;
    }
</style>

