export default {
    namespaced: true,
    state: {
        is_collapse: false, // 折叠状态
        menu: [], // 菜单数据
        breadcrumb: [],
        activeIndex: null,
        navbread: {},
    },
    mutations: {
        // 设置折叠状态
        SET_COLLAPSE_STATUS(state, data) {
            state.is_collapse = data;
        },
        ADD_BREADCRUMB: (state, route) => {
            let result = state.breadcrumb.every(item => {
                return item.mid !== route.item.mid
            })
            if (result) {
                let param = null
                if (route.sub) {
                    param = {
                        parentChinese: route.sub.guideChinese,
                        parentEnglish: route.sub.guideEnglish,
                        entry: route.sub.entry,
                        path: route.sub.activeRule ? route.sub.activeRule + route.item.path : route.item.path,
                        mid: route.item.mid,
                        childChinese: route.item.guideChinese,
                        childEnglish: route.item.guideEnglish,
                    }
                } else {
                    param = {
                        entry: route.item.entry,
                        path: route.item.activeRule ? route.item.activeRule + route.item.path : route.item.path,
                        mid: route.item.mid,
                        childChinese: route.item.guideChinese,
                        childEnglish: route.item.guideEnglish,
                    }
                }
                state.breadcrumb.push(param)
                sessionStorage.setItem('breadcrumb', JSON.stringify(state.breadcrumb))
            }
        },
        REMOVE_BREADCRUMB: (state, route) => {
            if (state.breadcrumb.length > 1) {
                for (let i in state.breadcrumb) {
                    if (route.mid == state.breadcrumb[i].mid) {
                        state.breadcrumb.splice(i, 1)
                    }
                }
            }
            sessionStorage.setItem('breadcrumb', JSON.stringify(state.breadcrumb))
        },
        CLEAR_BREADCRUMB: (state) => {
            state.breadcrumb = []
            state.navbread = {}
        },
        CHANGE_ACTIVE_INDEX: (state, i) => {
            state.activeIndex = i
        },
        ADD_NAV_BREAD: (state, route) => {
            if (route.sub) {
                state.navbread = route && route.bread ? route.bread : {
                    parentChinese: route.sub.guideChinese,
                    parentEnglish: route.sub.guideEnglish,
                    entry: route.sub.entry,
                    path: route.sub.activeRule ? route.sub.activeRule + route.item.path : route.item.path,
                    mid: route.item.mid,
                    childChinese: route.item.guideChinese,
                    childEnglish: route.item.guideEnglish,
                }
            } else {
                state.navbread = route && route.bread ? route.bread : {
                    entry: route.item.entry,
                    path: route.item.activeRule ? route.item.activeRule + route.item.path : route.item.path,
                    mid: route.item.mid,
                    childChinese: route.item.guideChinese,
                    childEnglish: route.item.guideEnglish,
                }
            }
        },
        SET_MENU(state, data) {
            state.menu = data;
        }
    },
    actions: {
        // 设置折叠状态
        setCollapseStatus({commit}, data) {
            commit('SET_COLLAPSE_STATUS', data)
        },

        addBreadCrumb({commit}, data) {
            commit('ADD_BREADCRUMB', data)
        },
        delBreadCrumb: ({commit}, data) => {
            commit('REMOVE_BREADCRUMB', data)
        },
        clearBreadCrumb: ({commit}, data) => {
            commit('CLEAR_BREADCRUMB', data)
        },
        setActiveIndex: ({commit}, data) => {
            commit('CHANGE_ACTIVE_INDEX', data)
        },
        addNavBread: ({commit}, data) => {
            commit('ADD_NAV_BREAD', data)
        },
        saveMenu: ({commit}, data) => {
            commit('SET_MENU', data)
        },
    }
}